import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import msiLogo from '../images/msi-logo.jpg'

const Heading = styled.h1`
  margin-top: 1rem;
  font-size: 2rem;
  font-weight: bold;
`

const SubHeading = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <img src={msiLogo} style={{ maxWidth: '400px', marginBottom: '1em' }} alt="MyStay International"/>
    <Heading style={{ marginTop: '1rem' }}>MyRoomStay.com.au is an initiative of the MyStay International (MSI) Group</Heading>
    <SubHeading>For any enquiries, please <a href="https://mystayinternational.com/contact-us/" target="_blank">contact us</a> at MSI</SubHeading>
  </Layout>
)

export default IndexPage
